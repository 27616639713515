export enum GA4EventName {
  ADD_OWN_AUDIO = 'AddOwnAudio',
  ADD_SLIDESHOW = 'AddSlideshow',
  START_DRAWING = 'StartDrawing',
  SAVED_POSTER = 'SavePoster',
  GETTY_IMAGE_LIMIT_REACHED = 'GettyImageLimitReached',
  EXTRACTED_GETTY_STICKER_LIMIT_REACHED = 'ExtractedGettyLimitReached',
  GETTY_VIDEO_LIMIT_REACHED = 'GettyVideoLimitReached',
  VIDEO_LIMIT_DIALOG = 'VideoLimitDialog',
  REMOVE_BACKGROUND = 'RemoveBackground',
  REMOVE_BACKGROUND_VIDEO = 'RemoveBackgroundVideo',
  SEARCH_BACKGROUND = 'SearchBackground',
  SEARCH_STORYBLOCKS_ILLUSTRATIONS = 'StoryblocksIllustrationsSearch',
  SEARCH_PIXABAY_ILLUSTRATIONS = 'PixabayIllustrationsSearch',
  ADDED_SLIDESHOW_ANIMATION = 'AddedSlideshowAnimation',
  ADDED_POSTER_ANIMATION = 'AddedPosterAnimation',
  CREATE_POSTER = 'CreatePoster',
  UPLOAD_FONT = 'UploadFont',
  DIGITAL_DISPLAY_PUBLISHED = 'DigitalDisplayPublished',
  EDITOR_ONBOARDING_VISITED = 'EditorOnboarding_Visited',
  EDITOR_ONBOARDING_SKIPPED = 'EditorOnboarding_Skipped',
  DOWNLOAD_CAMPAIGN_PDF = 'EmailPerformance_DownloadCampaign_PDF',
  DOWNLOAD_CAMPAIGN_CSV = 'EmailPerformance_DownloadCampaign_CSV',
  DOWNLOAD_MAILING_LIST_CSV = 'EmailPerformance_DownloadMailingList',
  ACCOUNT_ADDED = 'AccountAdded',
  ACCOUNT_DELETED = 'AccountDeleted',
  ACCOUNT_ADDED_MYSTUFF = 'AccountAdded_MyStuff',
  POST_PUBLISHED = 'PostPublished',
  POST_SCHEDULED = 'PostScheduled',
  PUBLISHING_INTRO_VIDEO = 'PublishingIntroVideo',
  PUBLISHING_ONBOARDING = 'PublishingOnboarding',
  MYSTUFF_SOCIALPOSTS_CREATEDESIGN = 'MyStuff_SocialPosts_CreateDesign',
  SOCIALWIZARD_ACCOUNTADDED = 'SocialWizard_AccountAdded',
  SOCIALWIZARD_NUMPLATFORMS = 'SocialWizard_NumPlatforms',
  SOCIALWIZARD_EDITDESIGN = 'SocialWizard_EditDesign',
  SOCIALWIZARD_RESIZEOPTIONS = 'SocialWizard_ResizeOptions',
  SOCIALWIZARD_AUTORESIZE = 'SocialWizard_AutoResize',
  MEDIA_MODAL_OPEN = 'MediaModal_Open',
  UPLOADS_MODAL_OPEN = 'UploadsModal_Open',
  EMAILMAKER_EMBEDVIDEO = 'EmailMaker_EmbedVideo',
  SOCIALWIZARD_AICAPTIONS_CLICKED = 'SocialWizard_AICaptions_Clicked',
  SOCIALWIZARD_AICAPTIONS_ADDED = 'SocialWizard_AICaptions_Added',
  SOCIALWIZARD_AICAPTIONS_FIRSTGEN = 'SocialWizard_AICaptions_FirstGen',
  SOCIALWIZARD_AICAPTIONS_MOREIDEAS = 'SocialWizard_AICaptions_MoreIdeas',
  SOCIALWIZARD_AICAPTIONS_STARTOVER = 'SocialWizard_AICaptions_StartOver',
  SOCIALWIZARD_AICAPTIONS_ONBOARDING_TRYNOW = 'SocialWizard_AICaptions_Onboarding_TryNow',
  SOCIALWIZARD_AICAPTIONS_ONBOARDING_LATER = 'SocialWizard_AICaptions_Onboarding_Later',
  SOCIALWIZARD_AICAPTIONS_REMINDER_VISIBLE_CLICK = 'SocialWizard_AICaptions_Clicked_Popover',
  SOCIALWIZARD_AICAPTIONS_RECENTS = 'SocialWizard_AICaptions_Recents',
  SOCIALWIZARD_AICAPTIONS_UPSELLING = 'SocialWizard_AICaptions_Upselling',
  SOCIALWIZARD_AICAPTIONS_LIMIT_REACHED = 'SocialWizard_AICaptions_DailyLimitHit',
  SOCIALWIZARD_UPLOADMEDIA_CLICKED = 'SocialWizard_UploadMedia_Clicked',
  SOCIALWIZARD_UPLOADMEDIA_CREATED = 'SocialWizard_UploadMedia_Created',
  TEMPLATES_MENU_OPEN = 'TemplatesMenu_Open',
  TEXT_MENU_OPEN = 'TextMenu_Open',
  LAYOUT_MENU_OPEN = 'LayoutMenu_Open',
  SLIDESHOW_MENU_OPEN = 'SlideshowMenu_Open',
  BACKGROUND_MENU_OPEN = 'BackgroundMenu_Open',
  QR_CODE_ADDED = 'AddedQRCode',
  TEXT_ITEM_ADDED = 'AddedTextItem',
  MORE_OPTIONS_OPEN = 'MoreOptions_Open',
  RECORD_MENU_OPEN = 'RecordMenu_Open',
  UPLOADSMODAL_RECORDED_MEDIA = 'UploadsModal_RecordedMedia',
  UPLOADSMODAL_RECORDED_MEDIA_ADDED = 'UploadsModal_RecordedMedia_Added',
  UPLOADSMODAL_UPLOAD_MEDIA = 'UploadsModal_UploadMedia',
  EMAILMAKER_UPLOAD_MEDIA = 'EmailMaker_UploadMedia',
  DESIGNER_PROFILE_CLICK = 'DesignerProfileClick_PosterBuilder',
  AI_IMAGE_MENU_OPEN = 'AIImageMenu_Open',
  AI_IMAGE_MENU_GENERATE = 'AIImageMenu_Generate',
  AI_IMAGE_TOTAL_GENERATIONS = 'AIImage_TotalGenerations',
  MEDIA_MODAL_AI_IMAGE_GENERATE_YOUR_OWN = 'MediaModal_AIImage_GenerateYourOwn',
  MEDIA_MODAL_AI_IMAGE_VIEW_ALL = 'MediaModal_AIImage_ViewAll',
  MEDIA_MODAL_AI_IMAGE_GENERATE = 'MediaModal_AIImage_Generate',
  MediaModal_AudioFilters = 'MediaModal_AudioFilters',
  CREATE_DESIGN_FROM_MEDIA_CLICKED = 'CreatePosterFromUpload_Clicked',
  CREATE_DESIGN_FROM_MEDIA_CREATED = 'CreatePosterFromUpload_Created',
  CONTENTPLANNER_ADD_EMAIL = 'ContentPlanner_AddEmail',
  CONTENTPLANNER_ADD_SOCIAL_POST = 'ContentPlanner_AddSocialPost',
  CONTENTPLANNER_ADD_CUSTOM_EVENT = 'ContentPlanner_AddCustomEvent',
  EMAILMAKER_SUBJECT_LINE_AI_WRITER_CLICKED = 'EmailMaker_SubjectLine_AIWriter_Clicked',
  EMAILMAKER_SUBJECT_LINE_AI_WRITER_GENERATED = 'EmailMaker_SubjectLine_AIWriter_Generated',
  TIMELINE_OPEN = 'Timeline_Open',
  TIMELINE_OPEN_BOTTOM_BAR = 'Timeline_Open_BottomBar',
  TIMELINE_OPEN_AUDIO_ICON = 'Timeline_Open_AudioIcon',
  TRIM_AUDIO_TIMELINE = 'TrimAudio_Timeline',
  TRIM_VIDEO_TIMELINE = 'TrimVideo_Timeline',
  TRIM_AUDIO_MODAL = 'TrimAudio_Modal',
  TRIM_VIDEO_MODAL = 'TrimVideo_Modal',
  SIGN_UP_GOOGLE = 'Google',
  SIGN_UP_FACEBOOK = 'Facebook',
  SIGN_UP_APPLE = 'Apple',
  EMAILMAKER_STYLES_VISITED = 'EmailMaker_Styles_Visited',
  EMAILMAKER_STYLES_APPLIED = 'EmailMaker_Styles_Applied',
  EMAILMAKER_STYLES_SEARCHED = 'EmailMaker_Styles_Searched',
  EMAILMAKER_STYLES_PILLS = 'EmailMaker_Styles_Pills',

  EMAILMAKER_ADD_SECTION = 'EmailMaker_AddSection',
  EMAILMAKER_SECTION_SELECT = 'EmailMaker_SectionSelect',
  EMAILMAKER_SECTION_SAVE = 'EmailMaker_SectionSave',
  EMAILMAKER_SAVED_SECTION_SELECT = 'EmailMaker_SavedSectionSelect',
  EMAILMAKER_ADD_ELEMENT = 'EmailMaker_AddElement',
  EMAILMAKER_SECTIONS_PUBLISHED = 'EmailMaker_SectionsPublished',

  EMAILMAKER_MAILINGLIST = 'EmailMaker_MailingList',
  EMAILMAKER_MAILINGLIST_MYSTUFF_TOPBUTTON = 'EmailMaker_MailingList_MyStuff_TopButton',
  EMAILMAKER_MAILINGLIST_MYSTUFF_PLUSBUTTON = 'EmailMaker_MailingList_MyStuff_PlusButton',
  EMAILMAKER_MAILINGLIST_MYSTUFF_BANNER = 'EmailMaker_MailingList_MyStuff_Banner',
  EMAILMAKER_MAILINGLIST_SIGNUP_FORM = 'EmailMaker_MailingList_SignUpForm',
  SIGNUPFORM_CREATE = 'SignUpForm_Create',
  SIGNUPFORM_CREATE_MYSTUFF_TOPBUTTON = 'SignUpForm_Create_MyStuff_TopButton',
  SIGNUPFORM_CREATE_MYSTUFF_PLUSBUTTON = 'SignUpForm_Create_MyStuff_PlusButton',
  SIGNUPFORM_CREATE_MYSTUFF_BANNER = 'SignUpForm_Create_MyStuff_Banner',
  SIGNUPFORM_DEFAULT_FIELDS_TOGGLE = 'SignUpForm_DefaultFieldsToggle',
  SIGNUPFORM_CUSTOM_FIELDS_TOGGLE = 'SignUpForm_CustomFieldsToggle',
  BUSINESS_PROFILES_IMPORT = 'BusinessProfiles_Import',
  BUSINESS_PROFILES_CREATE = 'BusinessProfiles_Create',
  BUSINESS_PROFILES_COMPLETE = 'BusinessProfiles_Complete',
  RESIZE_DESIGN_SEARCH = 'Resize_Design_Search',
  MENU_MAKER_FROM_ADD_TEXT_MENU = 'MenuMakerFromAddTextMenu',
  MENU_MAKER_FROM_CANVAS_EDIT_BUTTON = 'MenuMakerFromCanvasEditButton',
  AI_SUBTITLE_CLICK_MENU = 'AISubtitle_Click_Menu',
  AI_SUBTITLE_CLICK_CONTEXTUAL_MENU = 'AISubtitle_Click_Contextual',
  AI_SUBTITLE_GENERATE = 'AISubtitles_Generate',
  AI_SUBTITLE_TEMPLATE_PRESET_SELECTED = 'AISubtitles_Template_Preset_Selected',

  COPY_STYLES_CLICKED = 'CopyStyles_Clicked',

  EMAILMAKER_MERGE_TAGS_ADDED = 'EmailMaker_MergeTags_Added',
  EMAILMAKER_MERGER_TAGS_IN_CAMPAIGN = 'EmailMaker_MergeTags_Campaign_Sent',
  CREATE_MODAL_BROWSE_DESIGN_TEMPLATES = 'CreateBottomBar_Designs_Browse',
  CREATE_MODAL_BLANK_DESIGN = 'CreateBottomBar_Designs_Blank',
  CREATE_MODAL_BROWSE_EMAIL_TEMPLATES = 'CreateBottomBar_Emails_Browse',
  CREATE_MODAL_BLANK_EMAIL = 'CreateBottomBar_Emails_Blank',
  AI_VOICE_GENERATED = 'AIVoice_Generated',
  AI_VOICE_ADDED = 'AIVoice_Added',
  AI_VOICE_LIMIT_REACHED = 'AIVoice_LimitReached',
  BUSINESS_PROFILES_EMAIL_EDITOR_SOCIAL_LINK = 'BP_EmailEditor_SocialLink',
  BUSINESS_PROFILES_DESIGN_EDITOR_SOCIAL_LINK = 'BP_DesignEditor_SocialLink',
  BUSINESS_PROFILES_DESIGN_EDITOR_QR_CODE = 'BP_DesignEditor_QRCode',
  BUSINESS_PROFILES_DESIGN_EDITOR_SCHEDULE = 'BP_DesignEditor_Schedule',
  BRANDKITS_NEW_GET_FROM_WEBSITE = 'BrandKits_New_GetFromWebsite',
  BRANDKITS_NEW_START_FROM_SCRATCH = 'BrandKits_New_StartFromScratch',
  BUSINESS_PROFILE_POST_ON_GBP = 'BusinessProfile_PostonGBP',
  BUSINESS_PROFILE_EDIT = 'BusinessProfile_Edit',
}

export enum GA4EventParam {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
}

export enum GA4EventTextTypeParam {
  PLAIN = 'plain',
  FANCY = 'fancy',
  TRANSCRIPT = 'transcript',
  SLIDESHOW = 'slideshow',
}

export enum GA4EventParamName {
  SEARCH_TERM = 'searchTerm',
  TYPE = 'type',
  SLIDE_NUMBER = 'slideNumber',
  PLATFORM = 'platform',
  NUM_PLATFORMS = 'num_platforms',
  SIGNUP_PARAM_NAME = 'method',
  TERM = 'term',
  SEATS = 'seats',
  HASHED_ID = 'hashedId',
  USER_PREMIUM_LEVEL = 'userPremiumLevel',
  SOURCE = 'source',
  SIGNUP_FORM_FIELD_NAME = 'fieldName',
  SIGNUP_FORM_CUSTOM_FIELD_INDEX = 'customFieldIndex',
  MENU_OPENED_FROM = 'menuOpenedFrom',
  AI_SUBTITLE_TEMPLATE_ID = 'aiSubtitleTemplateId',
  MERGE_TAGS = 'mergeTags',
  VALUE = 'value',
}

export enum GA4EventSources {
  CONTEXTUAL_MENU = 'ContextualMenu',
  MOBILE_BOTTOM_BAR = 'BottomBar',
  LEFT_TOOLBAR = 'LeftToolbar',
}

export enum EditorMediaEvent {
  Audio_Fade_Add = 'Editor_Audio_Fade_Add',
  Audio_Fade_Removed = 'Editor_Audio_Fade_Removed',
  EDITOR_RECORDEDMEDIA_ADDED = 'Editor_RecordedMedia_Added',
  EDITOR_RECORDEDMEDIA_RETAKE = 'Editor_RecordedMedia_Retake',
}

export enum StyleType {
  BRAND_KIT = 'Brand Kit',
  ALL_STYLES = 'All Styles',
  RECENTS = 'Recents',
}

export const EMAILMAKER_ADD_SECTION_BLANK_SECTIONS = 'Empty';

export const trackPublishingGA4Events = (event: string, params?: Record<string, string>): void => {
  window.PMW.gtm.trackGA4CustomEvent(event, params);
};

export const trackPosterBuilderGA4Events = (event: string, params?: Record<string, string>): void => {
  window.PMW.gtm.trackGA4CustomEvent(event, params);
};
